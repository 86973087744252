@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.campaign-details {
  border-bottom: 1px solid rgba(48, 48, 47, 0.25);
  border-top: 1px solid rgba(48, 48, 47, 0.25);
  display: grid;
  font-size: 19px;
  grid-gap: 20px;
  grid-template-columns: 1fr 2fr;
}

.current-page-breadcrumb {
  color: $brand-red;
}

.description {
  font-size: 19px;
}

.header-label {
  color: $grey-600;
  font-size: 14px;
  line-height: 1;
}

.router-link-active {
  color: #000;
}

.v-breadcrumbs li {
  font-size: 12px;
}
