@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


audio {
  &:focus {
    outline: none;
  }
}

